import httpClient  from '../api';
const API_CONTROLLER = 'report-ktkt/';

export default {
    getReportFeeOnBehalf(search) {
        let url = API_CONTROLLER + 'get-report-fee-on-behalf';
        return httpClient.get(url, {
            params: {
                fromDate: search.fromDate,
                toDate: search.toDate,
                branchId: search.branchId,
                orderId: search.orderId,
                customerId: search.customerId,
                keyword: search.keyword
            }
        });
    },

    getReportGoodsFeeOnBehalfForKtkt(search) {
        let url = API_CONTROLLER + 'get-report-goods-on-behalf-for-ktkt';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                fromDate: search.fromDate,
                toDate: search.toDate,
                branchId: search.branchId,
                orderId: search.orderId,
                customerId: search.customerId,
                keyword: search.keyword,
                serial: search.serial
            }
        });
    },
}